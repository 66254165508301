body {
  margin: 0;
  font-family: "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

* {
  box-sizing: border-box;
}

a,
a:active,
a:visited {
  color: #008a61;
  text-decoration: none;
}

body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}
